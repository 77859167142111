import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    globalThis.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function useOnScreen<Element extends HTMLElement>(): [
  boolean,
  React.RefCallback<Element>
] {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    [setIsIntersecting]
  );

  const currentElement = useCallback(
    (ele: Element | null) => {
      if (ele) {
        observer.observe(ele);
      } else {
        observer.disconnect();
        setIsIntersecting(false);
      }
    },
    [observer, setIsIntersecting]
  );

  return [isIntersecting, currentElement];
}
